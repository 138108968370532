export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'orderId',
    sortable: false,
    sortField: '',
    label: 'field.partnerTransactionId',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'orderStatus',
    sortable: false,
    sortField: '',
    label: 'field.payment_status',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'denom',
    sortable: false,
    sortField: '',
    label: 'field.amount_diamond',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'userid',
    sortable: false,
    sortField: '',
    label: 'field.game_id',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'serverid',
    sortable: false,
    sortField: '',
    label: 'field.serverId',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
]
